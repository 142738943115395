import React, { Component } from "react";
import { StaticQuery, graphql } from "gatsby";

import ProductSelect from "../../components/ProductSelect/product-select";
import { NextCollection } from "../../components/ProductSelect/next-collection";
import { tearribleStyles } from "../../types";

class Products extends Component {
	render() {
		const domain =
			process.env.GATSBY_MYSHOPIFY_URL || "tearribles.myshopify.com";

		const collection = this.props.data.allShopifyCollection.edges[0].node;
		const tearriblesData =
			this.props.data.allShopifyCollection.edges[0].node.products.filter(
				(node) => {
					return (
						!node.tags.includes("Fire Hose") &&
						!node.tags.includes("Christmas") &&
						!node.tags.includes("Crinkle Paper") &&
						!node.tags.includes("Hide")
					);
				},
			);

		const christmasData = [];

		const fireHoseData =
			this.props.data.allShopifyCollection.edges[0].node.products.filter(
				(node) => {
					return (
						node.tags.includes("Fire Hose") &&
						!node.tags.includes("Hide")
					);
				},
			);

		const crinklePaperData =
			this.props.data.allShopifyCollection.edges[0].node.products.filter(
				(node) => {
					return (
						node.tags.includes("Crinkle Paper") &&
						!node.tags.includes("Hide")
					);
				},
			);

		return (
			<>
				{domain === "tearribles.myshopify.com" ? (
					<ProductSelect
						{...this.props}
						title="Halloween Tearrors"
						sort={(a, b) => {
							if (!a.size || !b.size) return 0;

							const aSize = tearribleStyles[a.size]?.index;
							const bSize = tearribleStyles[b.size]?.index;

							if (aSize < bSize) return -1;
							if (aSize > bSize) return 1;
							return 0;
						}}
						data={{
							allShopifyCollection: {
								edges: [
									{
										node: {
											products: fireHoseData,
										},
									},
								],
							},
						}}
					/>
				) : null}
				<ProductSelect
					{...this.props}
					title="Original Tearribles"
					sort={(a, b) => {
						if (!a.size || !b.size) return 0;

						const aSize = tearribleStyles[a.size]?.index;
						const bSize = tearribleStyles[b.size]?.index;

						if (aSize < bSize) return -1;
						if (aSize > bSize) return 1;
						return 0;
					}}
					data={{
						allShopifyCollection: {
							edges: [
								{
									node: {
										...collection,
										products: tearriblesData,
									},
								},
							],
						},
					}}
				/>
				<NextCollection {...this.props} />
			</>
		);
	}
}

export default (props) => (
	<StaticQuery
		query={graphql`
			query tearriblesQuery {
				allShopifyCollection(filter: { title: { in: "Tearribles" } }) {
					edges {
						node {
							title
							handle
							products {
								id
								shopifyId
								legacyResourceId
								title
								descriptionHtml
								productType
								tags
								handle
								collections {
									title
								}
								featuredImage {
									originalSrc
									localFile {
										childImageSharp {
											fluid {
												...GatsbyImageSharpFluid_withWebp_noBase64
											}
										}
									}
								}
								variants {
									shopifyId
									legacyResourceId
									title
									id
									sku
									price
									presentmentPrices {
										price {
											amount
											currencyCode
										}
									}
									compareAtPrice
									availableForSale
								}
							}
						}
					}
				}
			}
		`}
	>
		{(data) => <Products data={data} {...props} />}
	</StaticQuery>
);
